<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">Quote Requests</h3>
        <button
          :disabled="ui.loading"
          class="btn btn-control d-inline-flex ms-2"
          @click="getQuotes"
        >
          <img alt="Refresh" src="@/assets/icons/icon-sync.svg" />
        </button>
      </div>
      <div class="search-wrapper mb-0">
        <img alt="Icon search" src="@/assets/icons/icon-search.svg" />
        <input
          v-model="query"
          aria-label="Search"
          placeholder="Search"
          type="text"
          @input="search"
        />
      </div>
    </div>
    <div v-if="ui.loading || ui.deleteLoading" class="text-center mt-4">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else class="page-content">
      <div class="table-responsive mt-4 mb-2">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Agent</th>
              <th>Loan Type</th>
              <th>State</th>
              <th>Property Type</th>
              <th>Loan Amount</th>
              <th>Created On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="customQuote"
              v-for="(customQuote, index) in customQuotes"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>
                <div
                  class="customQuote__title"
                  @click="previewCustomQuote(customQuote)"
                >
                  {{ customQuote.fullName | titleCase }}
                </div>
              </td>
              <td>{{ customQuote.email }}</td>
              <td>
                <span v-if="customQuote.agent" class="text-success">
                  {{ customQuote.agent.fullName }}
                </span>
                <a
                  v-else
                  class="text-decoration-none"
                  href="#"
                  @click="assignCustomQuote(customQuote)"
                >
                  <img alt="assign" src="@/assets/icons/icon-profile.svg" />
                  Assign
                </a>
              </td>
              <td>{{ customQuote.loanType }}</td>
              <td>{{ customQuote.state ? customQuote.state : 'N/A' }}</td>
              <td>{{ customQuote.propertyType }}</td>
              <td>{{ customQuote.loanAmount | money }}</td>
              <td>
                {{ formatDate(customQuote.createdAt, "MMM D, Y, h:mm A") }}
              </td>
              <td>
                <div :name="'quote-' + index" class="btn-modal">
                  <img
                    alt="More button"
                    class="icon-more"
                    src="@/assets/icons/icon-more.svg"
                  />
                </div>
                <tippy
                  :to="'quote-' + index"
                  animateFill="false"
                  boundary="window"
                  class="table-menu"
                  interactive="true"
                  placement="bottom-end"
                  theme="light"
                  trigger="click"
                  zIndex="1039"
                >
                  <div class="table-menu-block">
                    <ul>
                      <li class="action-label">Actions</li>
                      <li class="cursor-pointer">
                        <div @click="editCustomQuote(customQuote)">
                          <img
                            alt="Edit"
                            src="@/assets/icons/icon-edit-normal.svg"
                          />
                          Edit
                        </div>
                      </li>
                      <li class="cursor-pointer" v-if="isGranted('ROLE_CEO_ASSISTANT')">
                        <div @click="reassignCustomQuote(customQuote)">
                          <img
                            alt="Preview"
                            src="@/assets/icons/icon-profile.svg"
                          />
                          Reassign
                        </div>
                      </li>
                      <li class="cursor-pointer">
                        <div @click="deleteCustomQuote(customQuote.id)">
                          <img
                            alt="Delete"
                            src="@/assets/icons/icon-delete.svg"
                          />
                          Delete
                        </div>
                      </li>
                    </ul>
                  </div>
                </tippy>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          v-if="customQuotes.length === 0"
          class="modal-alert modal-alert_advice text-center"
        >
          No Records Found
        </div>
      </div>
      <Paging :page="currentPage"
        :paging="paging"
        @limitChanged="pagingUpdated"
        @pageChanged="pagingUpdated">
      </Paging>
    </div>
    <vue-page-transition name="fade">
      <preview
        v-if="modals.preview"
        :customQuote="selectedQuote"
        @close="(modals.preview = false), (selectedQuote = {})"
      />
    </vue-page-transition>

    <vue-page-transition name="fade">
      <assign
        v-if="modals.assign"
        :agents="agents"
        :customQuote="selectedQuote"
        :reassign="modals.reassign"
        @close="
          (modals.assign = false),
            (selectedQuote = {}),
            (modals.reassign = false)
        "
        @success="getQuotes"
      />
    </vue-page-transition>

    <vue-page-transition name="fade">
      <edit
        v-if="modals.edit"
        :agents="agents"
        :customQuote="selectedQuote"
        @close="(modals.edit = false), (selectedQuote = {})"
        @success="getQuotes"
      />
    </vue-page-transition>
  </div>
</template>

<script>
import Preview from "./Modals/Preview";
import Assign from "./Modals/Assign";
import Edit from "./Modals/Edit";
import Paging from "@/components/Paging";
import debounce from "@/utils/debounce";

export default {
  name: "CustomQuote",
  components: { Preview, Assign, Edit, Paging },
  data() {
    return {
      ui: {
        loading: true,
        deleteLoading: false
      },
      customQuotes: [],
      agents: [],
      modals: {
        preview: false,
        assign: false,
        reassign: false,
        edit: false
      },
      selectedQuote: {},
      currentPage: 1,
      query: '',
      paging: {
        offset: 0,
        limit: 10,
        total: 0
      },
      search: debounce(() => {
        this.paging.offset = 0
        this.searchData()
      }, 350)
    };
  },
  methods: {
    getQuotes() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/custom_quote")
        .then(res => {
          this.customQuotes = res.data.data;
          this.agents = res.data.agents;
          this.searchData();
        })
        .catch(err => {
          this.alertError(err.response.statusText);
          this.ui.loading = false;
        });
    },
    searchData() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/custom_quote/search", {
          params: {
            query: this.query,
            offset: this.paging.offset,
            limit: this.paging.limit
          }
        })
        .then(res => {
          this.customQuotes = res.data.data;
          this.paging = res.data.paging
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    deleteCustomQuote(id) {
      if (confirm("Are you sure?")) {
        this.ui.deleteLoading = true;
        this.$http
          .get(`/api/v1/custom_quote/delete/${id}`)
          .then(() => {
            this.alertSuccess("Custom Quote successfully deleted!");
            this.getQuotes();
          })
          .catch(err => {
            this.alertError(err.response.statusText);
          })
          .finally(() => {
            this.ui.deleteLoading = false;
          });
      }
    },
    previewCustomQuote(customQuote) {
      this.selectedQuote = customQuote;
      this.modals.preview = true;
    },
    assignCustomQuote(customQuote) {
      this.selectedQuote = customQuote;
      this.modals.assign = true;
    },
    reassignCustomQuote(customQuote) {
      this.selectedQuote = customQuote;
      this.modals.reassign = true;
      this.modals.assign = true;
    },
    editCustomQuote(customQuote) {
      this.selectedQuote = customQuote;
      this.modals.edit = true;
    }, 
    pagingUpdated(paging) {
      this.paging = paging;
      this.currentPage = paging.currentPage
      this.searchData();
    },
  },
  filters: {
    titleCase: function(value) {
      if (value) {
        return value
          .toLowerCase()
          .replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
      }
    }
  },
  created() {
    this.getQuotes();
  }
};
</script>

<style lang="scss" scoped>
.customQuote {
  &__title {
    text-decoration: none;
    width: fit-content;

    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 1px;
      background: black;
      transition: all 0.5s;
    }

    &:hover {
      cursor: pointer;
      &:after {
        width: 0;
      }
    }
  }
}
</style>
