<template>
  <div>
    <div class="modal modal-center">
      <div class="modal-bg" @click="$emit('close')"></div>
      <div class="modal-block">
        <div class="modal-body">
          <div class="title modal-body-container mb-0 pb-3">
            <button class="btn btn-control close-btn" @click="$emit('close')">
              <img alt="" src="@/assets/icons/icon-close.png" />
            </button>
            {{ customQuote.fullName | titleCase }}
            <img
              v-if="customQuote.agent"
              alt="success"
              class="ms-2"
              src="@/assets/icons/icon-status-ok.svg"
            />
            <img
              v-else
              alt="error"
              class="ms-2"
              src="@/assets/icons/icon-status-error.svg"
            />
            <p>{{ customQuote.email }}</p>
          </div>
          <div class="container mt-4">
            <center>
              <h5>"{{ reassign ? "Reassign" : "Assign" }} Quote Request"</h5>
              <div class="form-group full-width">
                <multiselect
                  v-model="agent"
                  :allow-empty="false"
                  :close-on-select="true"
                  :options="agents"
                  :searchable="true"
                  :show-labels="false"
                  class="full-width"
                  label="fullName"
                  placeholder="Agents"
                  style="width: 300px"
                  track-by="id"
                >
                </multiselect>
              </div>
              <div class="form-group">
                <button
                  :disabled="ui.btnLoading"
                  class="btn btn-primary btn-lg mt-3"
                  @click="assign"
                >
                  <span
                    v-if="ui.btnLoading"
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                  <span v-else>Submit</span>
                </button>
              </div>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Assign",
  props: {
    customQuote: Object,
    agents: Array,
    reassign: {
      type: Boolean,
      default: false
    }
  },
  components: { Multiselect },
  data() {
    return {
      ui: {
        btnLoading: false
      },
      agent: {}
    };
  },
  methods: {
    setAgent() {
      if (this.customQuote.agent) {
        this.agent = {
          id: this.customQuote.agent.id,
          fullName: this.customQuote.agent.fullName
        };
      } else {
        this.agent = this.agents[0];
      }
    },
    assign() {
      this.ui.btnLoading = true;
      let formData = new FormData();
      formData.append("agent", this.agent.id);
      let url = `/api/v1/custom_quote/assign/${this.customQuote.id}`;
      if (this.reassign) {
        url = `/api/v1/custom_quote/reassign/${this.customQuote.id}`;
      }
      this.$http
        .post(url, formData)
        .then(res => {
          this.alertSuccess(res.data.message);
          this.$emit("success");
          this.$emit("close");
        })
        .catch(err => {
          this.alertError(err.response.statusText);
        })
        .finally(() => {
          this.ui.btnLoading = false;
        });
    }
  },
  filters: {
    titleCase: function(value) {
      if (value) {
        return value
          .toLowerCase()
          .replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
      }
    }
  },
  created() {
    this.setAgent();
  }
};
</script>

<style lang="scss" scoped>
.modal.modal-center {
  .modal-block {
    height: 60vh;
    padding: 20px;
    margin: 5vh auto;
    overflow-y: auto;
    border-radius: 8px;
  }

  .modal-body {
    padding: 30px;
  }

  .title {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;

    p {
      opacity: 0.46;
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }

    .status {
      box-sizing: border-box;
      border: 1px solid #0076ff;
      border-radius: 12px;
      background-color: #ffffff;
      color: #0078ff;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      padding: 0.4rem;
      margin-left: 1rem;
    }
  }

  .close-btn {
    float: right;

    &:hover {
      background-color: #fff;
    }
  }
}
</style>
