<template>
  <div>
    <div class="modal modal-center">
      <div class="modal-bg" @click="$emit('close')"></div>
      <div class="modal-block">
        <div class="modal-body">
          <div class="title modal-body-container mb-0 pb-3">
            <button class="btn btn-control close-btn" @click="$emit('close')">
              <img alt="" src="@/assets/icons/icon-close.png" />
            </button>
            Edit Quote Request
          </div>
          <div v-if="ui.loading" class="text-center mt-4">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <form v-else class="mt-4" @submit="update">
            <div class="container">
              <fieldset>
                <legend>Personal Details</legend>
                <div class="row">
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="fullNameField">Full name</label>
                      <input
                        id="fullNameField"
                        v-model="fields.fullName"
                        :class="{ 'input-filled': fields.fullName.length }"
                        class="form-control"
                        type="text"
                      />
                    </div>
                    <span
                      v-for="(error, index) in getError('fullName', errors)"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="emailField">Email</label>
                      <input
                        id="emailField"
                        v-model="fields.email"
                        :class="{ 'input-filled': fields.email.length }"
                        class="form-control"
                        type="email"
                      />
                    </div>
                    <span
                      v-for="(error, index) in getError('email', errors)"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="phoneNumberField">Phone number</label>
                      <input
                        id="phoneNumberField"
                        v-model="fields.phoneNumber"
                        v-mask="'(###) ###-####'"
                        :class="{ 'input-filled': fields.phoneNumber.length }"
                        class="form-control"
                        pattern="^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$"
                        placeholder="(xxx) xxx-xxxx"
                        type="tel"
                      />
                    </div>
                    <span
                      v-for="(error, index) in getError('phoneNumber', errors)"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="zipCodeField">Zip Code</label>
                      <input
                        id="zipCodeField"
                        v-model="fields.zipCode"
                        :class="{ 'input-filled': fields.zipCode.length }"
                        class="form-control"
                        type="number"
                        @change="updateState"
                      />
                    </div>
                    <span
                      v-for="(error, index) in getError('zipCode', errors)"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-12 my-2">
                    <div class="form-group">
                      <label aria-label="fieldState">State</label>
                      <multiselect id="fieldState"
                                  v-model="fields.state"
                                  :allow-empty="true"
                                  :close-on-select="true"
                                  :options="states"
                                  :searchable="true"
                                  :show-labels="false"
                                  class="full-width" style="width: 300px"
                                  placeholder="Select state">
                      </multiselect>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset class="mt-4">
                <legend>Other Details</legend>
                <div class="row">
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="loanTypeField">Loan type</label>
                      <multiselect
                        id="loanTypeField"
                        v-model="fields.loanType"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="loanTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        style="width: 300px"
                        @select="changeLoanType"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError('loanType', errors)"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="propertyTypeField">Property type</label>
                      <multiselect
                        id="propertyTypeField"
                        v-model="fields.propertyType"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="propertyTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        style="width: 300px"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError('propertyType', errors)"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="creditScoreField">Credit score</label>
                      <input
                        id="creditScoreField"
                        v-model="fields.creditScore"
                        :class="{ 'input-filled': fields.creditScore.length }"
                        class="form-control"
                        type="text"
                      />
                    </div>
                    <span
                      v-for="(error, index) in getError('creditScore', errors)"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="propertyUsedField">Property used</label>
                      <multiselect
                        id="propertyUsedField"
                        v-model="fields.propertyUsed"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="propertyUsed"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        style="width: 300px"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError('propertyUsed', errors)"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="employmentStatusField"
                        >Employment status</label
                      >
                      <multiselect
                        id="employmentStatusField"
                        v-model="fields.employmentStatus"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="employmentStatuses"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        style="width: 300px"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'employmentStatus',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="isShowIncomeProofField"
                        >Show proof of income</label
                      >
                      <multiselect
                        id="isShowIncomeProofField"
                        v-model="fields.isShowIncomeProof"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="radioTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        label="label"
                        style="width: 300px"
                        track-by="value"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'isShowIncomeProof',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-12 my-2">
                    <div class="form-group">
                      <label for="isForeclosureField"
                        >Bankruptcy, short sale, or foreclosure in the last 3
                        years?</label
                      >
                      <multiselect
                        id="isForeclosureField"
                        v-model="fields.isForeclosure"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="radioTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        label="label"
                        style="width: 300px"
                        track-by="value"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'isForeclosure',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                </div>

                <!-- Refinance Steps Start -->
                <div v-if="isRefinance" class="row">
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceYearField">Home Purchase year</label>
                      <multiselect
                        id="refinanceYearField"
                        v-model="fields.refinanceYear"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="purchaseYears"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        style="width: 300px"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_year',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceEstimatePropertyValueField"
                        >Estimate property value</label
                      >
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          $
                        </span>
                        <input
                          id="refinanceEstimatePropertyValueField"
                          v-model="fields.refinanceEstimatePropertyValue"
                          :class="{
                            'input-filled':
                              fields.refinanceEstimatePropertyValue.length
                          }"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_estimatePropertyValue',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceRemainingBalanceField"
                        >Remaining 1st mortgage balance</label
                      >
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          $
                        </span>
                        <input
                          id="refinanceRemainingBalanceField"
                          v-model="fields.refinanceRemainingBalance"
                          :class="{
                            'input-filled':
                              fields.refinanceRemainingBalance.length
                          }"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_remainingBalance',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceMortgageRateField"
                        >1st mortgage interest rate</label
                      >
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          %
                        </span>
                        <input
                          id="refinanceMortgageRateField"
                          v-model="fields.refinanceMortgageRate"
                          :class="{
                            'input-filled': fields.refinanceMortgageRate.length
                          }"
                          class="form-control"
                          step="0.01"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_mortgageRate',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceKindOfRateField"
                        >Type of rate do you have!</label
                      >
                      <multiselect
                        id="refinanceKindOfRateField"
                        v-model="fields.refinanceKindOfRate"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="rateTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        style="width: 300px"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_kindOfRate',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceIsSecondMortgageField"
                        >Do you have second mortgage!</label
                      >
                      <multiselect
                        id="refinanceIsSecondMortgageField"
                        v-model="fields.refinanceIsSecondMortgage"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="radioTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        label="label"
                        style="width: 300px"
                        track-by="value"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_isSecondMortgage',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceBorrowCashField"
                        >Borrow additional cash</label
                      >
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          $
                        </span>
                        <input
                          id="refinanceBorrowCashField"
                          v-model="fields.refinanceBorrowCash"
                          :class="{
                            'input-filled': fields.refinanceBorrowCash.length
                          }"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_borrowCash',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceMonthlyIncomeField"
                        >Monthly Income</label
                      >
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          $
                        </span>
                        <input
                          id="refinanceMonthlyIncomeField"
                          v-model="fields.refinanceMonthlyIncome"
                          :class="{
                            'input-filled': fields.refinanceMonthlyIncome.length
                          }"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_monthlyIncome',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceMonthlyExpenseField"
                        >Monthly Expenses</label
                      >
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          $
                        </span>
                        <input
                          id="refinanceMonthlyExpenseField"
                          v-model="fields.refinanceMonthlyExpense"
                          :class="{
                            'input-filled':
                              fields.refinanceMonthlyExpense.length
                          }"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_monthlyExpense',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="refinanceIsFHALoanField">Is FHA loan</label>
                      <multiselect
                        id="refinanceIsFHALoanField"
                        v-model="fields.refinanceIsFHALoan"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="radioTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        label="label"
                        style="width: 300px"
                        track-by="value"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_isFHALoan',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-12 my-2">
                    <div class="form-group">
                      <label for="refinanceLateMortgageField"
                        >Any late mortgage payments in the last 12
                        months?</label
                      >
                      <multiselect
                        id="refinanceLateMortgageField"
                        v-model="fields.refinanceLateMortgage"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="lateMortgages"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        label="label"
                        style="width: 300px"
                        track-by="value"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'refinance_lateMortgage',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                </div>

                <!-- Purchase Steps Start -->
                <div v-else class="row">
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="purchaseIsFirstPropertyField"
                        >Is first property purchase</label
                      >
                      <multiselect
                        id="purchaseIsFirstPropertyField"
                        v-model="fields.purchaseIsFirstProperty"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="radioTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        label="label"
                        style="width: 300px"
                        track-by="value"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'purchase_isFirstProperty',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="purchaseSituationField"
                        >Property purchase situation</label
                      >
                      <multiselect
                        id="purchaseSituationField"
                        v-model="fields.purchaseSituation"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="propertyPurchaseSituations"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        style="width: 300px"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'purchase_situation',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="purchasePriceField">Purchase price</label>
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          $
                        </span>
                        <input
                          id="purchasePriceField"
                          v-model="fields.purchasePrice"
                          :class="{
                            'input-filled': fields.purchasePrice.length
                          }"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'purchase_price',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="purchaseDownPaymentField"
                        >Estimated down payment</label
                      >
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          %
                        </span>
                        <input
                          id="purchaseDownPaymentField"
                          v-model="fields.purchaseDownPayment"
                          :class="{
                            'input-filled': fields.purchaseDownPayment.length
                          }"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'purchase_downPayment',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="purchasePreferRateField">Type of rate</label>
                      <multiselect
                        id="purchasePreferRateField"
                        v-model="fields.purchasePreferRate"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="rateTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        style="width: 300px"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'purchase_preferRate',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-6 my-2">
                    <div class="form-group">
                      <label for="purchaseGrossAnnualIncomeField"
                        >Annual Household income</label
                      >
                      <div class="input-with-icon mb-2">
                        <span class="icon">
                          $
                        </span>
                        <input
                          id="purchaseGrossAnnualIncomeField"
                          v-model="fields.purchaseGrossAnnualIncome"
                          :class="{
                            'input-filled':
                              fields.purchaseGrossAnnualIncome.length
                          }"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'purchase_grossAnnualIncome',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                  <div class="col-12 my-2">
                    <div class="form-group">
                      <label for="purchaseIsRealEstateAgentField"
                        >Are you working with a real estate agent?</label
                      >
                      <multiselect
                        id="purchaseIsRealEstateAgentField"
                        v-model="fields.purchaseIsRealEstateAgent"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="radioTypes"
                        :searchable="true"
                        :show-labels="false"
                        class="full-width"
                        label="label"
                        style="width: 300px"
                        track-by="value"
                      >
                      </multiselect>
                    </div>
                    <span
                      v-for="(error, index) in getError(
                        'purchase_isRealEstateAgent',
                        errors
                      )"
                      :key="index"
                      class="error-msg"
                    >
                      {{ error }}
                    </span>
                  </div>
                </div>
              </fieldset>
              <div class="form-group mt-3">
                <button
                  :disabled="ui.btnLoading"
                  class="btn btn-primary btn-lg"
                >
                  <span
                    v-if="ui.btnLoading"
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                  <span v-else>Update</span>
                </button>
                <button
                  class="btn btn-secondary float-right"
                  type="button"
                  @click="$emit('close')"
                >
                  close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Edit",
  props: {
    customQuote: Object,
    agents: Array
  },
  components: { Multiselect },
  data() {
    return {
      ui: {
        btnLoading: false,
        loading: true
      },
      fields: {
        email: "",
        zipCode: "",
        loanType: "",
        state: '',
        fullName: "",
        creditScore: 0,
        propertyType: "",
        propertyUsed: "",
        employmentStatus: "",
        isForeclosure: { value: false, label: "No" },
        isShowIncomeProof: { value: false, label: "No" },
        phoneNumber: 0,
        purchasePrice: 0,
        purchasePreferRate: 0,
        purchaseSituation: "",
        purchaseDownPayment: 0,
        purchaseGrossAnnualIncome: 0,
        purchaseIsFirstProperty: { value: false, label: "No" },
        purchaseIsRealEstateAgent: { value: false, label: "No" },
        refinanceYear: "",
        refinanceBorrowCash: 0,
        refinanceKindOfRate: "",
        refinanceMortgageRate: 0,
        refinanceMonthlyIncome: 0,
        refinanceLateMortgage: "",
        refinanceMonthlyExpense: 0,
        refinanceIsFHALoan: { value: false, label: "No" },
        refinanceRemainingBalance: 0,
        refinanceEstimatePropertyValue: 0,
        refinanceIsSecondMortgage: { value: false, label: "No" },
        agent: ""
      },
      loanTypes: [],
      states: [],
      propertyTypes: [],
      purchaseYears: [],
      propertyPurchaseSituations: [],
      propertyUsed: [],
      rateTypes: [],
      employmentStatuses: [],
      radioTypes: [],
      lateMortgages: [],
      isRefinance: this.customQuote.isRefinance,
      errors: []
    };
  },
  methods: {
    getFormData() {
      this.ui.loading = true;
      /** Start Multiselect */
      this.loanTypes = ["Purchasing", "Refinancing"];
      this.propertyUsed = ["Primary Home", "Secondary Home", "Rental"];
      this.rateTypes = ["Adjustable", "Fixed"];

      this.propertyTypes = [
        "Single Family Home",
        "Condominium",
        "Townhome",
        "Multifamily Home"
      ];

      this.purchaseYears = [
        "2010-2021",
        "2000-2009",
        "1990-1999",
        "Before 1990"
      ];

      this.propertyPurchaseSituations = [
        "Signed a Purchase Agreement",
        "Offer Pending / Found Property",
        "Buying in 2-6 Months",
        "Researching Options"
      ];

      this.employmentStatuses = [
        "Employed",
        "Not Employment",
        "Self Employed",
        "Military",
        "Other"
      ];

      this.radioTypes = [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 }
      ];

      this.lateMortgages = [
        { label: "None", value: "none" },
        { label: "1 Late Payment", value: "1" },
        { label: "2+ Late Payments", value: "2+" }
      ];

      /** End Multiselect */

      /** Set Fields */
      this.fields.email = this.customQuote.email;
      this.fields.zipCode = this.customQuote.zipCode;
      this.fields.loanType = this.customQuote.loanType;
      this.fields.state = this.customQuote.state
      this.fields.fullName = this.customQuote.fullName;
      this.fields.creditScore = this.customQuote.creditScore;
      this.fields.propertyType = this.customQuote.propertyType;
      this.fields.propertyUsed = this.customQuote.propertyUsed;
      this.fields.employmentStatus = this.customQuote.employmentStatus;
      this.fields.phoneNumber = this.customQuote.phoneNumber;
      this.fields.purchasePrice = this.customQuote.purchasePrice;
      this.fields.purchasePreferRate = this.customQuote.purchasePreferRate;
      this.fields.purchaseSituation = this.customQuote.purchaseSituation;
      this.fields.purchaseDownPayment = this.customQuote.purchaseDownPayment;
      this.fields.purchaseGrossAnnualIncome = this.customQuote.purchaseGrossAnnualIncome;
      this.fields.refinanceYear = this.customQuote.refinanceYear;
      this.fields.refinanceBorrowCash = this.customQuote.refinanceBorrowCash;
      this.fields.refinanceKindOfRate = this.customQuote.refinanceKindOfRate;
      this.fields.refinanceMortgageRate = this.customQuote.refinanceMortgageRate;
      this.fields.refinanceMonthlyIncome = this.customQuote.refinanceMonthlyIncome;
      this.fields.refinanceMonthlyExpense = this.customQuote.refinanceMonthlyExpense;
      this.fields.refinanceRemainingBalance = this.customQuote.refinanceRemainingBalance;
      this.fields.refinanceEstimatePropertyValue = this.customQuote.refinanceEstimatePropertyValue;

      this.lateMortgages.forEach(item => {
        if (item.value === this.customQuote.refinanceLateMortgage) {
          this.fields.refinanceLateMortgage = item;
        }
      });

      /** Set Yes/No Label */
      this.fields.purchaseIsFirstProperty = this.customQuote
        .purchaseIsFirstProperty
        ? {
            value: 1,
            label: "Yes"
          }
        : {
            value: 0,
            label: "No"
          };

      this.fields.purchaseIsRealEstateAgent = this.customQuote
        .purchaseIsRealEstateAgent
        ? {
            value: 1,
            label: "Yes"
          }
        : {
            value: 0,
            label: "No"
          };

      this.fields.isForeclosure = this.customQuote.isForeclosure
        ? {
            value: 1,
            label: "Yes"
          }
        : {
            value: 0,
            label: "No"
          };

      this.fields.isShowIncomeProof = this.customQuote.isShowIncomeProof
        ? {
            value: 1,
            label: "Yes"
          }
        : {
            value: 0,
            label: "No"
          };

      this.fields.refinanceIsFHALoan = this.customQuote.refinanceIsFHALoan
        ? {
            value: 1,
            label: "Yes"
          }
        : {
            value: 0,
            label: "No"
          };

      this.fields.refinanceIsSecondMortgage = this.customQuote
        .refinanceIsSecondMortgage
        ? {
            value: 1,
            label: "Yes"
          }
        : {
            value: 0,
            label: "No"
          };

      this.fields.agent = this.customQuote.agent
        ? {
            id: this.customQuote.agent.id,
            fullName: this.customQuote.agent.fullName
          }
        : "";

      this.$http.get(`/api/v1/custom_quote/edit/${this.customQuote.id}`)
        .then((res) => {
          this.states = res.data.states
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.ui.loading = false
        })
    },
    update(e) {
      e.preventDefault();

      this.ui.btnLoading = true;
      let formData = new FormData();

      formData.append("email", this.fields.email ? this.fields.email : "");
      formData.append(
        "zipCode",
        this.fields.zipCode ? this.fields.zipCode : ""
      );
      formData.append(
        "loanType",
        this.fields.loanType ? this.fields.loanType : ""
      );
      formData.append('state', this.fields.state ? this.fields.state : '')
      formData.append(
        "fullName",
        this.fields.fullName ? this.fields.fullName : ""
      );
      formData.append(
        "creditScore",
        this.fields.creditScore ? this.fields.creditScore : ""
      );
      formData.append(
        "propertyType",
        this.fields.propertyType ? this.fields.propertyType : ""
      );
      formData.append(
        "propertyUsed",
        this.fields.propertyUsed ? this.fields.propertyUsed : ""
      );
      formData.append(
        "employmentStatus",
        this.fields.employmentStatus ? this.fields.employmentStatus : ""
      );
      formData.append(
        "isForeclosure",
        this.fields.isForeclosure.value ? this.fields.isForeclosure.value : 0
      );
      formData.append(
        "isShowIncomeProof",
        this.fields.isShowIncomeProof.value
          ? this.fields.isShowIncomeProof.value
          : 0
      );
      formData.append(
        "phoneNumber",
        this.fields.phoneNumber ? this.fields.phoneNumber : ""
      );
      formData.append(
        "purchase_price",
        this.fields.purchasePrice ? this.fields.purchasePrice : 0
      );
      formData.append(
        "purchase_preferRate",
        this.fields.purchasePreferRate ? this.fields.purchasePreferRate : ""
      );
      formData.append(
        "purchase_situation",
        this.fields.purchaseSituation ? this.fields.purchaseSituation : ""
      );
      formData.append(
        "purchase_downPayment",
        this.fields.purchaseDownPayment ? this.fields.purchaseDownPayment : 0
      );
      formData.append(
        "purchase_grossAnnualIncome",
        this.fields.purchaseGrossAnnualIncome
          ? this.fields.purchaseGrossAnnualIncome
          : 0
      );
      formData.append(
        "purchase_isFirstProperty",
        this.fields.purchaseIsFirstProperty.value
          ? this.fields.purchaseIsFirstProperty.value
          : 0
      );
      formData.append(
        "purchase_isRealEstateAgent",
        this.fields.purchaseIsRealEstateAgent.value
          ? this.fields.purchaseIsRealEstateAgent.value
          : 0
      );
      formData.append(
        "refinance_year",
        this.fields.refinanceYear ? this.fields.refinanceYear : ""
      );
      formData.append(
        "refinance_borrowCash",
        this.fields.refinanceBorrowCash ? this.fields.refinanceBorrowCash : 0
      );
      formData.append(
        "refinance_kindOfRate",
        this.fields.refinanceKindOfRate ? this.fields.refinanceKindOfRate : ""
      );
      formData.append(
        "refinance_mortgageRate",
        this.fields.refinanceMortgageRate
          ? this.fields.refinanceMortgageRate
          : 0
      );
      formData.append(
        "refinance_monthlyIncome",
        this.fields.refinanceMonthlyIncome
          ? this.fields.refinanceMonthlyIncome
          : 0
      );
      formData.append(
        "refinance_lateMortgage",
        this.fields.refinanceLateMortgage.value
          ? this.fields.refinanceLateMortgage.value
          : ""
      );
      formData.append(
        "refinance_monthlyExpense",
        this.fields.refinanceMonthlyExpense
          ? this.fields.refinanceMonthlyExpense
          : 0
      );
      formData.append(
        "refinance_isFHALoan",
        this.fields.refinanceIsFHALoan.value
          ? this.fields.refinanceIsFHALoan.value
          : 0
      );
      formData.append(
        "refinance_remainingBalance",
        this.fields.refinanceRemainingBalance
          ? this.fields.refinanceRemainingBalance
          : 0
      );
      formData.append(
        "refinance_estimatePropertyValue",
        this.fields.refinanceEstimatePropertyValue
          ? this.fields.refinanceEstimatePropertyValue
          : 0
      );
      formData.append(
        "refinance_isSecondMortgage",
        this.fields.refinanceIsSecondMortgage.value
          ? this.fields.refinanceIsSecondMortgage.value
          : 0
      );

      this.$http
        .post(`/api/v1/custom_quote/edit/${this.customQuote.id}`, formData)
        .then(res => {
          this.alertSuccess(res.data.message);
          this.$emit("success");
          this.$emit("close");
        })
        .catch(err => {
          if (err.response.data.errors && err.response.status === 400) {
            this.errors = err.response.data.errors;
            this.alertError(err.response.data.message);
          } else {
            this.alertError(err.response.statusText);
          }
        })
        .finally(() => {
          this.ui.btnLoading = false;
        });
    },
    changeLoanType(event) {
      this.isRefinance = (event === "Refinancing")
    },
    updateState() {
      this.$http.get(`/api/v1/custom_quote/edit/state_by_zip/${this.fields.zipCode}`)
        .then((res) => {
          if (res.data.state) {
            this.fields.state = res.data.state
          }
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
    }
  },
  created() {
    this.getFormData();
  }
};
</script>

<style lang="scss" scoped>
.modal.modal-center {
  .modal-block {
    height: 90vh;
    padding: 20px;
    margin: 5vh auto;
    overflow-y: auto;
    border-radius: 8px;
  }

  .modal-body {
    padding: 30px;
  }

  .title {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;

    p {
      opacity: 0.46;
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }

    .status {
      box-sizing: border-box;
      border: 1px solid #0076ff;
      border-radius: 12px;
      background-color: #ffffff;
      color: #0078ff;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      padding: 0.4rem;
      margin-left: 1rem;
    }
  }

  .close-btn {
    float: right;

    &:hover {
      background-color: #fff;
    }
  }

  .form-group {
    max-width: 100%;
  }

  legend {
    font-size: 1.3rem;
    color: #6c757d;
  }

  .float-right {
    float: right;
  }
}
</style>
