<template>
  <div>
    <div class="modal modal-center">
      <div class="modal-bg" @click="$emit('close')"></div>
      <div class="modal-block">
        <div class="modal-body">
          <div class="title modal-body-container mb-0 pb-3">
            <button class="btn btn-control close-btn" @click="$emit('close')">
              <img alt="" src="@/assets/icons/icon-close.png" />
            </button>
            {{ customQuote.fullName | titleCase }}
            <img
              v-if="customQuote.agent"
              alt="success"
              class="ms-2"
              src="@/assets/icons/icon-status-ok.svg"
            />
            <img
              v-else
              alt="error"
              class="ms-2"
              src="@/assets/icons/icon-status-error.svg"
            />
            <p>{{ customQuote.email }}</p>
          </div>
          <div class="container mt-4">
            <div class="list-group">
              <div class="list-group-item">
                <table class="table">
                  <tr>
                    <td colspan="3">
                      <div
                        class="border border-light rounded text-center my-2 text-muted"
                      >
                        Personal Details
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Full Name
                    </th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.fullName | titleCase }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Email Address
                    </th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.email }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Phone Number
                    </th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.phoneNumber | toUSANumber }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Zip Code
                    </th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.zipCode }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>-</td>
                    <td><span>{{ customQuote.state ? customQuote.state : 'N/A' }}</span></td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div
                        class="border border-light rounded text-center my-2 text-muted"
                      >
                        Other Details
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Loan Type</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.loanType }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Property Type</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.propertyType }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Credit Score</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.creditScore }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Property Used</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.propertyUsed }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Employment Status</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.employmentStatus }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Is show proof of income</th>
                    <td>-</td>
                    <td>
                      <span
                        v-if="customQuote.isShowIncomeProof"
                        class="text-success"
                        >Yes</span
                      >
                      <span v-else class="text-danger">No</span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Bankruptcy, short sale, or foreclosure <br />in the last 3
                      years
                    </th>
                    <td>-</td>
                    <td>
                      <span v-if="customQuote.isForeclosure" class="text-danger"
                        >Yes</span
                      >
                      <span v-else class="text-success">No</span>
                    </td>
                  </tr>
                </table>
              </div>
              <div v-if="isRefinance" class="list-group-item">
                <table class="table">
                  <tr>
                    <th>Purchase Year</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.refinanceYear }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Estimated value of the property</th>
                    <td>-</td>
                    <td>
                      <span>{{
                        customQuote.refinanceEstimatePropertyValue | money
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Remaining 1st mortgage balance</th>
                    <td>-</td>
                    <td>
                      <span>{{
                        customQuote.refinanceRemainingBalance | money
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>1st mortgage interest rate</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.refinanceMortgageRate }}%</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Type of rate do you have</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.refinanceKindOfRate }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Do you have a second mortgage?</th>
                    <td>-</td>
                    <td>
                      <span
                        v-if="customQuote.refinanceIsSecondMortgage"
                        class="text-success"
                        >Yes</span
                      >
                      <span v-else class="text-danger">No</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Additional Cash</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.refinanceBorrowCash | money }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Monthly Income</th>
                    <td>-</td>
                    <td>
                      <span>{{
                        customQuote.refinanceMonthlyIncome | money
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Monthly Expenses</th>
                    <td>-</td>
                    <td>
                      <span>{{
                        customQuote.refinanceMonthlyExpense | money
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Do you currently have a FHA loan?</th>
                    <td>-</td>
                    <td>
                      <span
                        v-if="customQuote.refinanceIsFHALoan"
                        class="text-success"
                        >Yes</span
                      >
                      <span v-else class="text-danger">No</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Any late mortgage payments in last 12 months?</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.refinanceLateMortgage }}</span>
                    </td>
                  </tr>
                </table>
              </div>
              <div v-else class="list-group-item">
                <table class="table">
                  <tr>
                    <th>Is first property purchase</th>
                    <td>-</td>
                    <td>
                      <span
                        v-if="customQuote.purchaseIsFirstProperty"
                        class="text-success"
                        >Yes</span
                      >
                      <span v-else class="text-danger">No</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Property purchase situation</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.purchaseSituation }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Purchase price</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.purchasePrice | money }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Estimated down payment</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.purchaseDownPayment }}%</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Type of rate</th>
                    <td>-</td>
                    <td>
                      <span>{{ customQuote.purchasePreferRate }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Annual household income</th>
                    <td>-</td>
                    <td>
                      <span>{{
                        customQuote.purchaseGrossAnnualIncome | money
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Are you working with a real estate agent?</th>
                    <td>-</td>
                    <td>
                      <span
                        v-if="customQuote.purchaseIsRealEstateAgent"
                        class="text-success"
                        >Yes</span
                      >
                      <span v-else class="text-danger">No</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Preview",
  props: { customQuote: Object },
  data: function() {
    return {
      isRefinance: this.customQuote.isRefinance
    };
  },
  filters: {
    titleCase: function(value) {
      if (value) {
        return value
          .toLowerCase()
          .replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal.modal-center {
  .modal-block {
    height: 90vh;
    padding: 20px;
    margin: 5vh auto;
    overflow-y: auto;
    border-radius: 8px;
  }

  .modal-body {
    padding: 30px;

    .list-group-item {
      border: 0px;

      table {
        tr {
          line-height: 20px;

          th,
          td {
            padding: 4px 0px !important;
          }

          th {
            width: 65%;
          }
        }
      }
    }
  }

  .title {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;

    p {
      opacity: 0.46;
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }

    .status {
      box-sizing: border-box;
      border: 1px solid #0076ff;
      border-radius: 12px;
      background-color: #ffffff;
      color: #0078ff;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      padding: 0.4rem;
      margin-left: 1rem;
    }
  }

  .close-btn {
    float: right;

    &:hover {
      background-color: #fff;
    }
  }
}
</style>
